import { extendTheme } from '@chakra-ui/react';

const breakpoints = {
  xs: '345px',
  sm: '668px',
  md: '968px',
  lg: '1200px',
  xl: '1400px',
  '2xl': '1920px',
};

const theme = {
  styles: {
    global: {
      'html, body': {
        backgroundColor: 'rgb(4, 3, 4)',
        fontFamily: 'Plus Jakarta Sans',
        fontSize: {
          md: '16px',
          sm: '14px',
          xs: '14px',
        },
      },
    },
  },
  breakpoints,
};
export default extendTheme(theme);
