exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-banban-story-tsx": () => import("./../../../src/pages/banban-story.tsx" /* webpackChunkName: "component---src-pages-banban-story-tsx" */),
  "component---src-pages-buy-now-pay-later-tsx": () => import("./../../../src/pages/buy-now-pay-later.tsx" /* webpackChunkName: "component---src-pages-buy-now-pay-later-tsx" */),
  "component---src-pages-collateral-sell-tsx": () => import("./../../../src/pages/collateral-sell.tsx" /* webpackChunkName: "component---src-pages-collateral-sell-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-installment-payments-tsx": () => import("./../../../src/pages/installment-payments.tsx" /* webpackChunkName: "component---src-pages-installment-payments-tsx" */),
  "component---src-pages-loans-for-loans-tsx": () => import("./../../../src/pages/loans-for-loans.tsx" /* webpackChunkName: "component---src-pages-loans-for-loans-tsx" */),
  "component---src-pages-sell-on-credit-tsx": () => import("./../../../src/pages/sell-on-credit.tsx" /* webpackChunkName: "component---src-pages-sell-on-credit-tsx" */)
}

